import React from "react";
import './resources.css'

const Resources = () =>{
    return <div className="resourcesPage" style={{ display: "flex", flexDirection: "column",alignItems: "center"}}>
        <h1>LOADING....</h1>
        <h2>Construction of this section is on the way ... hold tight </h2>
    </div>
}

export default Resources